import React from 'react';

function PrivacyPolicy() {
  return (
    <div className='privacy-policy-parent'>
      <h1>Privacy Policy</h1>
      <p>
        Last updated: 25.08.2024
      </p>

      <h2>Introduction</h2>
      <p>
        Welcome to the "My Baidibek" app developed by <strong>kz Beta</strong> . This Privacy Policy explains how we collect, use, and protect your personal information when you use our app. By using the "My Baidibek" app, you agree to the collection and use of information in accordance with this policy.
      </p>

      <h2>Information We Collect</h2>
      <p>
        We collect several types of information to provide and improve our services to you:
      </p>
      <ul>
        <li>
          <strong>Personal Information:</strong> When you register on our app, we require your phone number and WhatsApp contact information to verify your identity and facilitate communication between users.
        </li>
        <li>
          <strong>Post Content:</strong> If you post content or sales offers on the app, we collect the information provided in those posts. All posts are subject to moderation to ensure they are safe and appropriate.
        </li>
        <li>
          <strong>Location Data:</strong> For users who use the taxi services, we collect real-time location data to match you with available taxi partners and improve your service experience. Taxi partners' locations are tracked while they are on duty to ensure efficient service delivery.
        </li>
        <li>
          <strong>Service and Business Listings:</strong> Information related to services or businesses listed on the app is collected to provide users with relevant business information and recommendations.
        </li>
        <li>
          <strong>Usage Data:</strong> We may collect information on how the app is accessed and used, including device information, log data, and browsing activities.
        </li>
      </ul>

      <h2>How We Use Your Information</h2>
      <p>
        We use the collected information for various purposes:
      </p>
      <ul>
        <li>To provide and maintain our app and services.</li>
        <li>To verify your identity and manage your account registration and login.</li>
        <li>To notify you about changes to our app or services.</li>
        <li>To allow you to participate in interactive features of our app when you choose to do so.</li>
        <li>To provide customer support.</li>
        <li>To facilitate communication between users, such as through phone or WhatsApp contact.</li>
        <li>To gather analysis or valuable information to improve our app.</li>
        <li>To monitor the usage of our app and detect, prevent, and address technical issues.</li>
        <li>To ensure that the posts made by users are safe and appropriate by conducting moderation.</li>
      </ul>

      <h2>Data Sharing and Disclosure</h2>
      <p>
        We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except in the following situations:
      </p>
      <ul>
        <li>To comply with legal obligations.</li>
        <li>To protect and defend the rights or property of <strong>kz Beta</strong> .</li>
        <li>To prevent or investigate possible wrongdoing in connection with the app.</li>
        <li>To protect the personal safety of users of the app or the public.</li>
        <li>To protect against legal liability.</li>
      </ul>

      <h2>Security of Your Information</h2>
      <p>
        The security of your personal information is important to us, and we strive to implement and maintain reasonable, commercially acceptable security procedures and practices appropriate to the nature of the information we store, in order to protect it from unauthorized access, destruction, use, modification, or disclosure.
      </p>

      <h2>Retention of Your Information</h2>
      <p>
        We will retain your personal information only for as long as is necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
      </p>

      <h2>Your Data Protection Rights</h2>
      <p>
        Depending on your location, you may have certain rights regarding your personal information, including the right to access, correct, update, or request deletion of your personal information. To exercise these rights, please contact us at <a href="mailto:kz.ibeta.games@gmail.com"> kz.ibeta.games@gmail.com</a>.
      </p>

      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us at <a href="mailto:kz.ibeta.games@gmail.com"> kz.ibeta.games@gmail.com</a>.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
